import React from "react";
import Layout from "../../../components/Layout"

const DevelopmentPlanPage = () =>{
    return(
        <Layout>
            
        </Layout>
    );
}


export default DevelopmentPlanPage;